export const gettersTypes = {
  presenceCuratedTargets: '[navigation] presence curated targets',
};

const getters = {
  [gettersTypes.presenceCuratedTargets]: (state) => {
    if (!state.presence) return false;
    return state.presence.curated_targets || false;
  },
};

export default getters;
