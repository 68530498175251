import axios from '@/api/axios';

const getAllCompanyList = () => axios.get(`/company`);

const getCompanies = () => axios.get('/company');

const getCompany = (id) => axios.get(`/company/${id}`);

const getSpecializations = (id) => axios.get(`/company/${id}/specializations`);

const getSettings = (id) => axios.get(`/company/${id}/settings`);

const getRecordTypes = (id) => axios.get(`/company/${id}/audit/record-types`);

// change company
const changeSettings = (id, data) => axios.post(`/company/${id}/settings`, data);

const hideSelfTesting = (id, state) => axios.post(`/company/${id}/hide_self_testing?hide=${state}`);

const hideFeed = (id, state) => axios.post(`/company/${id}/hide_feed?hide=${state}`);

const changeFlags = (id, data) => axios.post(`/company/${id}/set_flags`, data);

const changeCompany = (id, data) => axios.put(`/company/${id}`, data);

const changeLogo = (id, data) => axios.post(`/company/${id}/logo`, data);

const changeServices = (id, data) => axios.put(`/company/${id}/services`, data);

// portfolio company
const getPortfolio = (id) => axios.get(`/company/${id}/portfolio`);

const downloadPortfolio = (id) =>
  axios
    .get(`/company/${id}/portfolio/download`)
    .then((response) => (window.location.href = response.data));

const createPortfolio = (id, data) => axios.post(`/company/${id}/portfolio`, data);

const deletePortfolio = (id, portfolioId) =>
  axios.delete(`/company/${id}/portfolio/${portfolioId}`);

// company employees
const getEmployees = (id) => axios.get(`/company/${id}/employee/`);

const getBanEmployees = (id) => axios.get(`/company/${id}/ban`);

const changeBanEmployees = (id, banned = 0) => axios.post(`/company/${id}/ban?banned=${banned}`);

const importEmployees = (id, data) =>
  axios.post(`/company/${id}/employee/file_invite`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

const getStatusesImportEmployees = (packNumber) => axios.get(`/company/load_errors/${packNumber}`);

const inviteEmployee = (id, userId) =>
  axios.post(`/company/${id}/employee/`, userId, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

const inviteEmailEmployee = (id, data) => axios.post(`/company/${id}/employee/emailinvite`, data);

const cancelInvitationEmployee = (id, employeeId) =>
  axios.delete(`/company/${id}/employee/${employeeId}`);

const changeEmployeeStatus = (id, employeeId, state) =>
  axios.put(`/company/${id}/employee/${employeeId}`, state, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

const getSubordinateEmployees = (companyId) =>
  axios.get(`/company/${companyId}/get_subordinate_users`);

const changeOrganizers = (id, employeeIds) =>
  axios.post(`/company/${id}/employee/organizers`, employeeIds);

// managers
const getManagers = (id) => axios.get(`/company/${id}/managers`);

const changeManagers = (id, data) => axios.put(`/company/${id}/managers`, data);

const deleteManager = (id, userId) => axios.delete(`/company/${id}/managers?user_id=${userId}`);

// company tests
const getTests360 = (id, forceIncludeSelftest) =>
  axios.get(
    `/company/${id}/tests/test360${
      forceIncludeSelftest ? `?force_include_selftest=${forceIncludeSelftest}` : ''
    }`
  );

const getSelfTests = (id) => axios.get(`/company/${id}/tests/self_tests`);

const getPersonalTests = (id) => axios.get(`/company/${id}/tests/personal`);

const getEnabledPersonalTests = (id) => axios.get(`/company/${id}/enabled_personal_tests`);

// открытие или закрытие тестов 360 для перепрохождения, по умолчанию закрыты
const reEvalLockOfTests360 = (id, data) => axios.post(`/company/${id}/reEvalLock`, data);

// открытие или закрытие тестов 360 для прохождения, по умолчанию открыты
const closeTests360 = (id, data) => axios.post(`/company/${id}/tests360/close`, data);

const setHiddenReports360 = (id, data) => axios.post(`/company/${id}/test360/set_hidden`, data);

const exportTests360 = (id, method = 'get', type = 'pivotExport', data) => {
  const config = {
    method,
    url: `/company/${id}/test360/${type}`,
    responseType: data.is_async ? 'json' : 'blob',
  };

  if (method === 'get') config.params = data;
  if (method === 'post') config.data = data;

  return axios(config);
};

const createTests360 = (id, data) => axios.post(`/company/${id}/test360/from_pack`, data);

const deleteTests360 = (id, data) => axios.post(`/company/${id}/tests/test360/delete`, data);

// reminders about tests
const dryRunRemindersTests360 = (id, data) =>
  axios.post(`/company/${id}/test360/check_box_reminder/dry-run`, data);

const dryRunRemindersSelfTests = (id, data) =>
  axios.post(`/company/${id}/self_tests/check_box_reminder/dry-run`, data);

const dryRunRemindersPersonalTests = (id, data) =>
  axios.post(`/company/${id}/motivation_tests/check_box_reminder/dry-run`, data);

const createRemindersTests360 = (id, data) =>
  axios.post(`/company/${id}/test360/check_box_reminder`, data);

const createRemindersSelfTests = (id, data) =>
  axios.post(`/company/${id}/self_tests/check_box_reminder`, data);

const createRemindersPersonalTests = (id, data) =>
  axios.post(`/company/${id}/motivation_tests/check_box_reminder`, data);

// company teams
const getTeams = (id, allTeams) =>
  axios.get(`/company/${id}/team/${allTeams ? `?all_teams=${allTeams}` : ''}`);

const getManagedTeams = (id) => axios.get(`/company/${id}/managed-teams`);

const getTeamsTree = (id, teamIds) =>
  axios.get(`/company/${id}/user_tree`, {params: {team_ids: teamIds}});

const checkTeamTests360 = (id, teamId) => axios.get(`/company/${id}/team/${teamId}/check_360`);

const createTeam = (id, teamName) =>
  axios.post(`/company/${id}/team/`, teamName, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

const changeTeam = (id, teamId, data) => axios.put(`/company/${id}/team/${teamId}`, data);

const deleteTeam = (id, teamId) => axios.delete(`/company/${id}/team/${teamId}`);

// company team groups
const getTeamGroupsFlat = (id) => axios.get(`/company/${id}/team_groups_flat`);

const getTeamGroups = (id) => axios.get(`/company/${id}/team_groups`);

const getTeamGroup = (id, teamGroupId) => axios.get(`/company/${id}/team_groups/${teamGroupId}`);

const createTeamGroup = (id, data) => axios.post(`/company/${id}/team_groups`, data);

const changeTeamGroup = (id, teamGroupId, data) =>
  axios.post(`/company/${id}/team_groups/${teamGroupId}`, data);

const changeAllTeamGroup = (id, teamGroupId, data) =>
  axios.put(`/company/${id}/team_groups/${teamGroupId}`, data);

const deleteTeamGroup = (id, teamGroupId) =>
  axios.delete(`/company/${id}/team_groups/${teamGroupId}`);

const getCompanyStatistic = (companyId, statisticType, args = {}) =>
  axios.get(`/company/${companyId}/statistic/${statisticType}`, {params: args});

// adaptation
const exportAdaptation = (id, dateFrom, dateTo) =>
  axios.get(`/company/${id}/adaptation/pivot_export?date_start=${dateFrom}&date_end=${dateTo}`, {
    responseType: 'blob',
  });

// roadmaps
const exportRoadmaps360 = (id, method = 'get', type = 'mass_export', data) => {
  const config = {
    method,
    url: `/company/${id}/roadmap360/${type}`,
    responseType: data.is_async ? 'json' : 'blob',
  };

  if (method === 'get') config.params = data;
  if (method === 'post') config.data = data;

  return axios(config);
};

// links
const getLinks = (id, linkType) =>
  axios.get(`/company/${id}/links${linkType ? `?link_type=${linkType}` : ''}`);

const createLink = (id, data) => axios.post(`/company/${id}/links`, data);

const changeLink = (id, data) => axios.put(`/company/${id}/links`, data);

const deleteLink = (id, linkId) => axios.delete(`/company/${id}/links/${linkId}`);

const switchAuthEmails = (id, switchMark = false) =>
  axios.post(`/company/${id}/password_mails?switch_mark=${switchMark}`);

const getRoadmaps = (id) => axios.get(`/company/${id}/board_summary`);

// targets
const getTargets = (id) => axios.get(`/company/${id}/targets`);

const getCuratorsByUser = (id, userId) =>
  axios.get(`/company/${id}/targets/curators_list/${userId}`);

const exportTargets = (id) => axios.get(`/company/${id}/targets/export`, {responseType: 'blob'});

const startReviewTargets = (id) => axios.post(`/company/${id}/start_targets_review`);

export default {
  getAllCompanyList,
  getCompanies,

  getCompany,
  getCompanyStatistic,
  getSpecializations,
  getSettings,
  getRecordTypes,

  changeSettings,
  hideSelfTesting,
  hideFeed,
  changeFlags,
  changeCompany,
  changeLogo,
  changeServices,

  getPortfolio,
  downloadPortfolio,
  createPortfolio,
  deletePortfolio,

  getEmployees,
  getBanEmployees,
  changeBanEmployees,
  importEmployees,
  getStatusesImportEmployees,
  inviteEmployee,
  inviteEmailEmployee,
  cancelInvitationEmployee,
  changeEmployeeStatus,
  getSubordinateEmployees,
  changeOrganizers,

  getManagers,
  changeManagers,
  deleteManager,

  getTests360,
  getSelfTests,
  getPersonalTests,
  getEnabledPersonalTests,
  reEvalLockOfTests360,
  closeTests360,
  setHiddenReports360,
  exportTests360,
  createTests360,
  deleteTests360,

  exportAdaptation,
  exportRoadmaps360,

  dryRunRemindersTests360,
  dryRunRemindersSelfTests,
  dryRunRemindersPersonalTests,
  createRemindersTests360,
  createRemindersSelfTests,
  createRemindersPersonalTests,

  getTeams,
  getManagedTeams,
  getTeamsTree,
  checkTeamTests360,
  createTeam,
  changeTeam,
  deleteTeam,

  getTeamGroupsFlat,
  getTeamGroups,
  getTeamGroup,
  createTeamGroup,
  changeTeamGroup,
  changeAllTeamGroup,
  deleteTeamGroup,

  getLinks,
  createLink,
  changeLink,
  deleteLink,

  switchAuthEmails,
  getRoadmaps,

  getTargets,
  getCuratorsByUser,
  exportTargets,
  startReviewTargets,
};
