export const SURVEYS_GET_LIMIT = 10;

export default {
  surveys: null,
  surveysCount: 0,
  researchmentTemplates: [],
  unattachedSurveys: [],
  isUnattachedSurveysLoading: false,
  researchments: [],
  researchmentCount: 0,
  feed: {},
  feedSettings: [],
  hasMore: false,
  researchment: {},
  researchmentSurveys: [],
  survey: {},
  surveyMailing: {},
  surveyWithResults: {},
  statistic: {},
  recipients: null,
  timetable: null,
  isLoading: false,
  isSubmitting: false,
  errors: null,
  validationErrors: null,
  companySurveysStatistic: {},
  companyTestsStatistic: {},
  testSession: {},
};
