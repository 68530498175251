import surveyApi from '@/api/survey';
import {mutationTypes} from '@/store/modules/survey/mutations';

export const actionsTypes = {
  getSurvey: '[survey] get survey',
  getSurveyPublic: '[survey] get survey public',
  createSurvey: '[survey] create survey',
  delSurvey: '[survey] del survey',
  createSurveyQuestion: '[survey] create survey question',
  editSurveyQuestion: '[survey] edit survey question',
  delSurveyQuestion: '[survey] del survey question',
  copySurveyQuestion: '[survey] copy survey question',
  getSurveyMailing: '[survey] get survey mailing',
  setSurveyMailing: '[survey] set survey mailing',
  setSurveyResults: '[survey] set survey results',
  testKeepAlive: '[survey] set test keep-alive',
  setSurveyTempResults: '[survey] set survey temp results',
  getSurveys: '[survey] get surveys',
  getUnattachedSurveys: '[survey] get unattached surveys',
  getResearchment: '[survey] get researchment',
  getResearchmentSurveysByCycle: '[survey] get researchment surveys list by cycle',
  createResearchment: '[survey] create researchment',
  copyResearchment: '[survey] copy researchment',
  saveResearchment: '[survey] save researchment',
  changeResearchmentStatus: '[survey] change researchment status',
  changeSurveyDates: '[survey] change researchment survey dates',
  assignResearchment: '[survey] assign researchment',
  delResearchment: '[survey] del researchment',
  rebuildResearchment: '[survey] researchment rebuild',
  startResearchmentCycle: '[survey] researchment start',
  getResearchments: '[survey] get researchments',
  getResearchmentTemplateList: '[survey] get researchment template list',
  createResearchmentTemplate: '[survey] create researchment template',
  getFeed: '[survey] get feed',
  getFeedSettings: '[survey] get feed settings',
  getSurveyResults: '[survey] get survey results',
  getSurveyStatistic: '[survey] get survey statistic',
  getCompanySurveysStatistic: '[survey] get company surveys statistic',
  getRecipients: '[survey] get recipients',

  copySurvey: '[survey] copy',
  changeSurvey: '[survey] change',
  changeSurveyBlock: '[survey] change block',
  changeSurveyStatus: '[survey] change status',
  sendSurvey: '[survey] send to participants',
  sendSurveyNow: '[survey] send now',
  setFeedSettings: '[survey] set feed settings',

  getResearchmentAssignee: '[survey] get researchment assignee',
  changeResearchmentAssignee: '[survey] researchment assignee',

  getTimetable: '[survey] get timetable',
  changeTimetable: '[survey] change timetable',

  exportResearchment: '[survey] export researchment',
};

const actions = {
  [actionsTypes.getSurvey](context, {companyId, survey_id, surveyType = 'survey', cycle = 1}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .getSurvey(companyId, survey_id, surveyType, cycle)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.exportResearchment](context, {companyId, researchmentId, email}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .exportResearchment(companyId, researchmentId, email)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccessState);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSurveyPublic](context, {survey_id, token = undefined}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .getSurveyPublic(survey_id, token)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSurveyMailing](context, {companyId, survey_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyMailingStart);
      surveyApi
        .getSurveyMailing(companyId, survey_id)
        .then((response) => {
          context.commit(mutationTypes.getSurveyMailingSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyMailingFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.setSurveyMailing](context, {companyId, survey_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyMailingStart);
      surveyApi
        .setSurveyMailing(companyId, survey_id, data)
        .then((response) => {
          context.commit(mutationTypes.getSurveyMailingSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyMailingFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.setSurveyResults](context, {survey_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .setSurveyResults(survey_id, data)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.testKeepAlive](context, {survey_id, data}) {
    return new Promise((resolve) => {
      surveyApi
        .testKeepAlive(survey_id, data)
        .then((response) => {
          context.commit(mutationTypes.testKeepAlive, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.testKeepAlive, result.response.data);
          resolve(result.response.data);
        });
    });
  },
  [actionsTypes.setSurveyTempResults](context, {survey_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .setSurveyTempResults(survey_id, data)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createSurvey](
    context,
    {companyId, title, survey_type = 'survey', bind_to_series = undefined}
  ) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .createSurvey(companyId, {survey_type, title}, bind_to_series)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccessState);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.delSurvey](context, {companyId, surveyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .delSurvey(companyId, surveyId)
        .then((response) => {
          context.commit(mutationTypes.getSurveySuccessState);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createSurveyQuestion](context, {companyId, surveyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .createSurveyQuestion(companyId, surveyId, data)
        .then((response) => {
          context.commit(mutationTypes.getSurveyQuestionSuccessState, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.editSurveyQuestion](context, {companyId, surveyId, question_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .editSurveyQuestion(companyId, surveyId, question_id, data)
        .then((response) => {
          context.commit(mutationTypes.getSurveyQuestionSuccessState, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.delSurveyQuestion](context, {companyId, surveyId, question_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .delSurveyQuestion(companyId, surveyId, question_id)
        .then((response) => {
          context.commit(mutationTypes.getSurveyQuestionSuccessState, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.copySurveyQuestion](context, {companyId, surveyId, question_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStart);
      surveyApi
        .copySurveyQuestion(companyId, surveyId, question_id)
        .then((response) => {
          context.commit(mutationTypes.getSurveyQuestionSuccessState, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSurveys](
    context,
    {
      companyId,
      surveyType = 'survey',
      limit = 10,
      offset = 0,
      search = '',
      status,
      date_start,
      date_end,
    }
  ) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveysStart);
      surveyApi
        .getSurveys(
          companyId,
          surveyType,
          false,
          offset,
          limit,
          search,
          status,
          date_start,
          date_end
        )
        .then((response) => {
          context.commit(mutationTypes.getSurveysSuccess, {...response.data, offset});
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveysFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getResearchment](context, {companyId, researchmentId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentStart);
      surveyApi
        .getResearchment(companyId, researchmentId)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getResearchmentSurveysByCycle](context, {companyId, researchmentId, cycle}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentStart);
      surveyApi
        .getResearchmentSurveysByCycle(companyId, researchmentId, cycle)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentSurveysSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getUnattachedSurveys](context, {companyId, search, surveyType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUnattachedSurveysStart);
      surveyApi
        .getUnattachedSurveys(companyId, search, surveyType)
        .then((response) => {
          context.commit(mutationTypes.getUnattachedSurveysSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getUnattachedSurveysFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getResearchments](
    context,
    {companyId, surveyType = 'survey', search = '', status}
  ) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentsStart);
      surveyApi
        .getResearchments(companyId, surveyType, search, status)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.rebuildResearchment](context, {companyId, researchmentId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentStart);
      surveyApi
        .rebuildResearchment(companyId, researchmentId)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.startResearchmentCycle](context, {companyId, researchmentId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentStart);
      surveyApi
        .startResearchmentCycle(companyId, researchmentId)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.createResearchment](context, {companyId, name, surveyType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createResearchmentStart);
      surveyApi
        .createResearchment(companyId, {name, survey_type: surveyType})
        .then((response) => {
          context.commit(mutationTypes.createResearchmentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.copyResearchment](context, {companyId, researchmentId, name, surveyType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createResearchmentStart);
      surveyApi
        .copyResearchment(companyId, researchmentId, {name, survey_type: surveyType})
        .then((response) => {
          context.commit(mutationTypes.createResearchmentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.saveResearchment](context, {companyId, researchmentId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.saveResearchmentStart);
      surveyApi
        .saveResearchment(companyId, researchmentId, data)
        .then((response) => {
          context.commit(mutationTypes.createResearchmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeResearchmentStatus](context, {companyId, researchmentId, status}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentStart);
      surveyApi
        .changeResearchmentStatus(companyId, researchmentId, status)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeSurveyDates](context, {companyId, researchmentId, surveyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentStart);
      surveyApi
        .changeSurveyDates(companyId, researchmentId, surveyId, data)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.assignResearchment](context, {companyId, researchmentId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.saveResearchmentStart);
      surveyApi
        .assignResearchment(companyId, researchmentId, data)
        .then((response) => {
          context.commit(mutationTypes.createResearchmentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.delResearchment](context, {companyId, researchmentId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.delResearchmentStart);
      surveyApi
        .delResearchment(companyId, researchmentId)
        .then((response) => {
          context.commit(mutationTypes.delResearchmentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.delResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.createResearchmentTemplate](
    context,
    {
      companyId,
      researchmentId,
      name,
      surveyType,
      isCommon = false,
      asTemplate = false,
      toCompanies = [],
    }
  ) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createResearchmentStart);
      surveyApi
        .copyResearchment(companyId, researchmentId, {
          name,
          survey_type: surveyType,
          common: isCommon,
          template: asTemplate,
          to_companies: toCompanies,
        })
        .then((response) => {
          context.commit(mutationTypes.createResearchmentSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.createResearchmentFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getResearchmentTemplateList](context, {companyId, surveyType}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getResearchmentTemplateListStart);
      surveyApi
        .getResearchments(companyId, surveyType, '', '', true)
        .then((response) => {
          context.commit(mutationTypes.getResearchmentTemplateListSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getResearchmentTemplateListFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getFeed](context, {companyId, surveyType = 'survey', limit, date_start, date_end}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getFeedStart);
      surveyApi
        .getFeed(companyId, surveyType, limit, date_start, date_end)
        .then((response) => {
          context.commit(mutationTypes.getFeedSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getFeedFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getFeedSettings](context, {companyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getFeedSettingsStart);
      surveyApi
        .getFeedSettings(companyId)
        .then((response) => {
          context.commit(mutationTypes.getFeedSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getFeedSettingsFailure);
          reject(result);
        });
    });
  },

  [actionsTypes.getSurveyResults](context, {companyId, survey_id, isTests = false}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyResultsStart);
      surveyApi
        .getSurveyResults(companyId, survey_id, isTests)
        .then((response) => {
          context.commit(mutationTypes.getSurveyResultsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyResultsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getRecipients](context, {companyId, survey_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getRecipientsStart);
      surveyApi
        .getRecipients(companyId, survey_id)
        .then((response) => {
          context.commit(mutationTypes.getRecipientsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getRecipientsFailure);
          reject(result);
        });
    });
  },
  [actionsTypes.getSurveyStatistic](context, {companyId, survey_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getSurveyStatisticSuccess, {});
      context.commit(mutationTypes.getSurveyStatisticStart);
      surveyApi
        .getSurveyStatistic(companyId, survey_id)
        .then((response) => {
          context.commit(mutationTypes.getSurveyStatisticSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getSurveyStatisticFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.getCompanySurveysStatistic](
    context,
    {companyId, dateStart, dateEnd, teamIds, surveyType = 'survey'}
  ) {
    return new Promise((resolve, reject) => {
      const type = surveyType === 'test' ? 'companyTestsStatistic' : 'companySurveysStatistic';
      context.commit(mutationTypes.getCompanySurveysStatisticSuccess, {
        type,
        payload: {},
      });
      context.commit(mutationTypes.getCompanySurveysStatisticStart);
      const args = {
        survey_type: surveyType,
        date_start: dateStart,
        date_end: dateEnd,
      };
      if (teamIds && teamIds.length > 0) {
        args.team_ids = teamIds.join(',');
      }
      surveyApi
        .getCompanySurveysStatistic(companyId, args)
        .then((response) => {
          context.commit(mutationTypes.getCompanySurveysStatisticSuccess, {
            type,
            payload: response.data,
          });
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(
            mutationTypes.getCompanySurveysStatisticFailure,
            result.response.data.message
          );
          reject(result);
        });
    });
  },

  [actionsTypes.copySurvey](context, {companyId, survey_id, toCompanies}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.copySurveyStart);
      surveyApi
        .copySurvey(companyId, survey_id, toCompanies)
        .then((response) => {
          context.commit(mutationTypes.copySurveySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.copySurveyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeSurvey](context, {companyId, survey_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeSurveyStart);
      surveyApi
        .changeSurvey(companyId, survey_id, data)
        .then((response) => {
          context.commit(mutationTypes.changeSurveySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeSurveyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeSurveyBlock](context, {companyId, survey_id, block, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeSurveyStart);
      surveyApi
        .changeSurveyBlock(companyId, survey_id, block, data)
        .then((response) => {
          context.commit(mutationTypes.changeSurveySuccessWithData, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeSurveyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.changeSurveyStatus](context, {companyId, survey_id, status}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeSurveyStart);
      surveyApi
        .changeSurveyStatus(companyId, survey_id, {status})
        .then((response) => {
          context.commit(mutationTypes.changeSurveyStatus, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeSurveyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.sendSurvey](context, {companyId, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.sendSurveyStart);
      surveyApi
        .sendSurvey(companyId, data)
        .then((response) => {
          context.commit(mutationTypes.sendSurveySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.sendSurveyFailure, result.response.data.message);
          reject(result);
        });
    });
  },
  [actionsTypes.sendSurveyNow](context, {companyId, surveyId}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.sendSurveyStart);
      surveyApi
        .sendSurveyNow(companyId, surveyId)
        .then((response) => {
          context.commit(mutationTypes.sendSurveySuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.sendSurveyFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.setFeedSettings](context, {companyId, feedSettings}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.setFeedSettingsStart);
      surveyApi
        .setFeedSettings(companyId, feedSettings)
        .then((response) => {
          context.commit(mutationTypes.setFeedSettingsSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.setFeedSettingsFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getResearchmentAssignee](context, {companyId, researchment_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTimetableStart);
      surveyApi
        .getResearchmentAssignee(companyId, researchment_id)
        .then((response) => {
          context.commit(mutationTypes.getTimetableSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTimetableFailure, result.response.status);
          reject(result);
        });
    });
  },
  [actionsTypes.changeResearchmentAssignee](context, {companyId, researchment_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTimetableStart);
      surveyApi
        .assignResearchment(companyId, researchment_id, data)
        .then((response) => {
          context.commit(mutationTypes.changeTimetableSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTimetableFailure, result.response.data.message);
          reject(result);
        });
    });
  },

  [actionsTypes.getTimetable](context, {companyId, survey_id}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getTimetableStart);
      surveyApi
        .getTimetable(companyId, survey_id)
        .then((response) => {
          context.commit(mutationTypes.getTimetableSuccess, response.data);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.getTimetableFailure, result.response.status);
          reject(result);
        });
    });
  },
  [actionsTypes.changeTimetable](context, {companyId, survey_id, data}) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeTimetableStart);
      surveyApi
        .changeTimetable(companyId, survey_id, data)
        .then((response) => {
          context.commit(mutationTypes.changeTimetableSuccess);
          resolve(response.data);
        })
        .catch((result) => {
          context.commit(mutationTypes.changeTimetableFailure, result.response.data.message);
          reject(result);
        });
    });
  },
};

export default actions;
